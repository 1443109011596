import React, { useState, useEffect } from 'react';
import {
    Container,
    TermsText
} from './CheckinUnscheduled.styles';
import {
    Button,
    CheckInText,
    ErrorCode
} from '../../Styles';
import {
    Title,
} from '../Mobile/Mobile.styles';
import Logo from '../../Components/Logo/Logo';
import Loader from '../../Components/Loader/Loader';
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisationData, LoginType, requestPermission, generateOtp } from '../../Redux/User/User-actions';
import config from '../../Configurations';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../Redux/root-reducer';
import Profile from '../Profile/Profile';
import Terms from '../Terms/Terms';
import moment from 'moment';
import { Content } from '../Welcome/Welcome.styles';
import Otp from '../Mobile/Otp';
import Mobile from '../Mobile/Mobile';
import LoginForm from '../LoginForm/LoginForm';
import { Checkbox, IconButton } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TermsAndConditions from '../Login/TermsAndConditions';

const qs = require('qs');

const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
};

const CheckinUnscheduled = () => {

    const dispatch = useDispatch();
    const location: any = useLocation();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.user);

    const [display, setDisplay] = useState(false);
    const [loading, setLoading] = useState(true);
    const [orgLogo, setOrgLogo] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState(0);
    const [termsChecked, setTermsChecked] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setDisplay(true);
        }, 500);

        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        const authCode = queryParams.authCode;
        const kioskId = queryParams.kioskId;

        if (kioskId && authCode) {
            dispatch(getOrganisationData(kioskId, authCode));
        }

    }, [location.search]);

    useEffect(() => {
        if (user.loading !== loading) {
            setLoading(user.loading);
        }

        if (!user.loading && user.error) {
            setError(user.error);
        }

        if (user.orgLogo) {
            setOrgLogo(`data:image/png;base64,${user.orgLogo}`);
        }
    }, [user]);

    useEffect(() => {
        if (step === 2) {
            sendOTP()
        }

        if (step === 4 && !user.showVisitorPhoto) {
            setStep(5)
        } else if (step === 5 && !user.tAndCFormEnabled) {
            setStep(6)
        }

        if (step === 6) {
            dispatch(requestPermission(
                user?.visitorName,
                user?.phone,
                user?.email,
                user?.meetingPurpose,
                user?.startDate ? moment(user?.startDate, "MMM D, YYYY").toDate() : (new Date()),
                moment(user?.startTime).toDate(),
                moment(user?.endTime).toDate(),
                user?.showVisitorPhoto ? user?.visitorPhoto : null,
                user?.toMeet?.id,
                user?.orgId,
                user?.visitorId,
                user?.meetingId,
                user?.sessionToken,
                user?.tAndCFormChecked,
                user?.visitorComments,
                user?.kioskId,
                user?.orgLogo,
                user?.siteId,
                (autoApprovalEnabled: boolean) => {
                    if (autoApprovalEnabled) {
                        history.push(`/Checkin?orgId=${user?.orgId}&meetingId=${user?.meetingId}`);
                    } else {
                        setStep(7)
                    }
                }
            ))
        }
    }, [step])

    const moveToOtp = (type: string) => {
        dispatch(LoginType(type));
        if (type === "checkin") {
            setStep(1)
        }
        //  else {
        //     history.push("/mobile");
        // }
    };

    const sendOTP = () => {
        let body: any = {}
        if (user?.phone && user?.email) {
            body["email"] = user?.email;
            body["phone"] = user?.phone;
        } else if (user?.email) {
            body["email"] = user?.email;
        } else if (user?.phone) {
            body["phone"] = user?.phone;
        }

        dispatch(generateOtp(user?.orgId || 1, body));
    };

    const renderSteps = () => {
        switch (step) {
            case 0:
                return (
                    <>
                        <motion.div
                            style={{ opacity: 0 }}
                            animate={display ? "open" : "closed"}
                            variants={variants}
                            transition={{ duration: 0.7 }}
                        >
                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '0 1rem' }}>
                                    <Checkbox checked={termsChecked} onClick={() => { setTermsChecked(!termsChecked) }} ></Checkbox>
                                    <p style={{ color: '#000000', marginBlock: '0', marginTop: '1.2rem' }}>I confirm that I have read and agree to the <a style={{ color: '#1771C1' }} onClick={() => { setOpenTerms(true) }} >Terms and Conditions.</a></p>
                                </div>
                                <Button disabled={!termsChecked} onClick={() => moveToOtp('checkin')} style={{ marginTop: "2rem", width: '85%' }}>
                                    <CheckInText>CHECKIN</CheckInText>
                                </Button>
                                <Button disabled={!termsChecked} onClick={() => moveToOtp('checkout')} style={{ backgroundColor: "#2484DC", marginTop: "0.5rem", width: '85%' }}>
                                    <CheckInText>CHECKOUT</CheckInText>
                                </Button>
                            </div>
                        </motion.div>
                    </>
                )
            case 1:
                return <Mobile setStep={setStep} nextStep={2} />
            case 2:
                return <Otp setStep={setStep} nextStep={3} resendOTP={() => { sendOTP() }} sendData={true} />
            case 3:
                return <LoginForm setStep={setStep} />
            case 4:
                return <Profile setStep={setStep} nextStep={5} />
            case 5:
                return <Terms setStep={setStep} nextStep={6} />
            case 6:
                return <Container>
                    <Content>
                        <Loader></Loader>
                    </Content>
                </Container>
            case 7:
                return <motion.div
                    style={{ opacity: 0 }}
                    animate={"open"}
                    variants={variants}
                    transition={{ duration: 1.5 }}
                >
                    <Content>
                        <Title>Hi, {user.visitorName}</Title>
                        <div style={{ width: "100%" }}>
                            <TermsText>
                                Thank you for checking in. Please expect your visit approval over {(user?.phone && user?.email) ? 'SMS or Email' : user?.phone ? 'SMS' : 'Email'}
                            </TermsText>
                        </div>
                    </Content>
                </motion.div>
            default:
                break;
        }
    }

    const hBack = () => {
        if (step === 5 && !user.showVisitorPhoto) {
            setStep(3)
        } else {
            setStep(step - 1)
        }
    }

    return openTerms ? (<TermsAndConditions onProceed={(status: boolean) => { setTermsChecked(status); setOpenTerms(false) }} />) :
        (<Container>
            {error ? (
                <div style={{ display: 'flex', flexDirection: "column", flex: 1, justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
                    <Title>Welcome to {config.copyrightName}'s Visitor Management</Title>
                    <ErrorCode>{error}</ErrorCode>
                </div>
            ) : (
                <>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {![0, 1, 3]?.includes(step) && <IconButton style={{ position: 'absolute', top: '10px', left: '10px' }} onClick={hBack}><KeyboardBackspaceIcon color={'black'} /></IconButton>}
                            <div style={{ alignItems: "center", display: 'flex', marginTop: '2rem' }}>
                                <Logo source={orgLogo} />
                            </div>
                            {renderSteps()}
                        </div>
                    )}
                </>
            )}
        </Container>)
};


export default CheckinUnscheduled;
