import React, { useState, useEffect } from 'react';
import { Container, } from './Login.styles';
import {
    Button,
    CheckInText,
    ErrorCode
} from '../../Styles';
import { Title } from '../Mobile/Mobile.styles';
import Logo from '../../Components/Logo/Logo';
import Loader from '../../Components/Loader/Loader';
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import { getEmailLinkData, Chechkout, LoginType, requestPermission, generateOtp } from '../../Redux/User/User-actions';
import config from '../../Configurations';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../Redux/root-reducer';
import Welcome from '../Welcome/Welcome';
import LoginFormView from '../LoginForm/LoginFormView';
import Comment from '../Comment/Comment';
import Profile from '../Profile/Profile';
import Terms from '../Terms/Terms';
import moment from 'moment';
import { Content, TermsText } from '../Welcome/Welcome.styles';
import Otp from '../Mobile/Otp';
import { saveVisitorInfoSuccess } from '../../Redux/Visitor/Visitor-actions';
import { Checkbox } from '@material-ui/core';
import TermsAndConditions from './TermsAndConditions';

const qs = require('qs');

const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
};

const Login = () => {

    const dispatch = useDispatch();
    const location: any = useLocation();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.user);

    const [display, setDisplay] = useState(false);
    const [emailLink, setEmailLink] = useState(false);
    const [loading, setLoading] = useState(true);
    const [orgLogo, setOrgLogo] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState(0);
    const [termsChecked, setTermsChecked] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setDisplay(true);
        }, 500);

        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        const mId = queryParams.mId;
        const accessKey = queryParams.accessKey;

        if (mId && accessKey) {
            setEmailLink(true);
            dispatch(getEmailLinkData(mId, accessKey));
        }
    }, [location.search]);

    useEffect(() => {
        if (user.loading !== loading) {
            setLoading(user.loading);
        }

        if (!user.loading && user.error) {
            if (user?.error == "Email Link Has Expired") {
                history.push("/linkExpired")
            } else {
                setError(user.error);
            }
        }

        if (user.orgLogo) {
            setOrgLogo(`data:image/png;base64,${user.orgLogo}`);
        }

    }, [user]);


    const sendOTP = () => {
        let body: any = {}
        if (user?.phone && user?.email) {
            body["email"] = user?.email;
            body["phone"] = user?.phone;
        } else if (user?.email) {
            body["email"] = user?.email;
        } else if (user?.phone) {
            body["phone"] = user?.phone;
        }

        dispatch(generateOtp(user?.orgId || 1, body));
    };

    useEffect(() => {
        if (step === 1) {
            sendOTP()
        }

        if (step === 4 && (!user?.hostAdditionalInfo || !user.visitorAdditionalInfo)) {
            setStep(5);
        } else if (step === 5 && !user.showVisitorPhoto) {
            setStep(6)
        } else if (step === 6 && !user.tAndCFormEnabled) {
            setStep(7)
        }

        if (step === 7) {
            dispatch(requestPermission(
                user?.visitorName,
                user?.phone,
                user?.email,
                user?.meetingPurpose,
                user?.startDate ? moment(user?.startDate, "MMM D, YYYY").toDate() : (new Date()),
                moment(user?.startTime, "hh:mm:ss A(Z)").toDate(),
                moment(user?.endTime, "hh:mm:ss A(Z)").toDate(),
                user?.showVisitorPhoto ? user?.visitorPhoto : null,
                user?.toMeet?.id,
                user?.orgId,
                user?.visitorId,
                user?.meetingId,
                user?.sessionToken,
                user?.tAndCFormChecked,
                user?.visitorComments,
                user?.kioskId,
                user?.orgLogo,
                user?.siteId,
                (autoApprovalEnabled) => {
                    if (autoApprovalEnabled) {
                        history.push(`/Checkin?orgId=${user?.orgId}&meetingId=${user?.meetingId}`);
                    } else {
                        setStep(8)
                    }
                }
            ))
        }
    }, [step])

    const moveToOtp = (type: string) => {
        dispatch(LoginType(type));
        if (emailLink) {
            setStep(user.otpVerificationEnabled ? 1 : 2)
        }
    };

    const checkout = () => {
        const time = new Date();
        const { orgId, visitorId, meetingId, sessionToken } = user;
        dispatch(Chechkout(orgId, visitorId, meetingId, time, sessionToken));
        setStep(9)
    };

    const viewVisitorDetails = () => {

        let visitorInfo = {
            meetingId: user?.meetingId,
            visitorId: user?.visitorId,
            orgLogo: user?.orgLogo,
        };
        dispatch(saveVisitorInfoSuccess(visitorInfo))
        history.push(`/Checkin?orgId=${user?.orgId}&meetingId=${user?.meetingId}`)
    }

    const renderSteps = () => {
        switch (step) {
            case 0:
                return (
                    <>
                        <motion.div
                            style={{ opacity: 0 }}
                            animate={display ? "open" : "closed"}
                            variants={variants}
                            transition={{ duration: 0.7 }}
                        >
                            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                                {user.visitorTypes.checkedIn ? (
                                    <>
                                        <Button onClick={viewVisitorDetails} style={{ marginTop: "2rem" }}>
                                            <CheckInText>VIEW DETAILS</CheckInText>
                                        </Button>
                                        <Button onClick={checkout} style={{ backgroundColor: "#2484DC", marginTop: "0.5rem" }}>
                                            <CheckInText>CHECKOUT</CheckInText>
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: '0 1rem' }}>
                                            <Checkbox checked={termsChecked} onClick={() => { setTermsChecked(!termsChecked) }} ></Checkbox>
                                            <p style={{ color: '#000000', marginBlock: '0', marginTop: '1.2rem' }}>I confirm that I have read and agree to the <a style={{ color: '#1771C1' }} onClick={() => { setOpenTerms(true) }} >Terms and Conditions.</a></p>
                                        </div>
                                        <Button disabled={!termsChecked} onClick={() => moveToOtp('checkin')} style={{ marginTop: "2rem", width: '85%' }}>
                                            <CheckInText>CHECKIN</CheckInText>
                                        </Button>
                                    </>
                                )}
                                {!user.visitorTypes.checkedIn && (
                                    <>
                                        <Button disabled={true} onClick={() => moveToOtp('checkout')} style={{ backgroundColor: "#2484DC", marginTop: "0.5rem", width: '85%' }}>
                                            <CheckInText>CHECKOUT</CheckInText>
                                        </Button>
                                    </>
                                )}
                            </div>
                        </motion.div>
                    </>
                )
            case 1:
                return <Otp setStep={setStep} nextStep={2} resendOTP={() => { sendOTP() }} sendData={false} />
            case 2:
                return <Welcome setStep={setStep} nextStep={3} />
            case 3:
                return <LoginFormView setStep={setStep} nextStep={4} />
            case 4:
                return <Comment setStep={setStep} nextStep={5} />
            case 5:
                return <Profile setStep={setStep} nextStep={6} />
            case 6:
                return <Terms setStep={setStep} nextStep={7} />
            case 7:
                return <Container>
                    <Content>
                        <Loader></Loader>
                    </Content>
                </Container>
            case 8:
                return <motion.div
                    style={{ opacity: 0 }}
                    animate={"open"}
                    variants={variants}
                    transition={{ duration: 1.5 }}
                >
                    <Content>
                        <Title>Hi, {user.visitorName}</Title>
                        <div style={{ width: "100%" }}>
                            <TermsText>
                                Thank you for checking in. Please expect your visit approval over {(user?.phone && user?.email) ? 'SMS or Email' : user?.phone ? 'SMS' : 'Email'}
                            </TermsText>
                        </div>
                    </Content>
                </motion.div>
            case 9:
                return (
                    <>
                        <Title>Hi, {user.visitorName}</Title>
                        <TermsText>{user.checkoutMessage}</TermsText>
                    </>
                )
            default:
                break;
        }
    }

    return openTerms ? (<TermsAndConditions onProceed={(status: boolean) => { setTermsChecked(status); setOpenTerms(false) }} />) :
        (<Container>
            {
                error ? (
                    <div style={{ display: 'flex', flexDirection: "column", flex: 1, justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
                        <Title>Welcome to {config.copyrightName}'s Visitor Management</Title>
                        <ErrorCode>{error}</ErrorCode>
                    </div >
                ) : (
                    <>
                        {loading ? (
                            <Loader />
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ alignItems: "center", display: 'flex', marginTop: '2rem' }}>
                                    <Logo source={orgLogo} />
                                </div>
                                {renderSteps()}
                            </div>
                        )}
                    </>
                )}
        </Container >)

};


export default Login;
