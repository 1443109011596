import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../Screens/Login/Login';
import DoorUnlocked from '../Screens/DoorUnlocked/DoorUnlocked';
import Checkin from '../Screens/Checkin/Checkin'
import Home from '../Screens/Home/Home';
import visitorPass from '../Screens/ViewVisitorPass/viewVisitorPass';
import Snackbars from '../Components/Snackbar/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Redux/root-reducer';
import { SetNotify } from '../Redux/Snackbar/Snackbar-actions';
import LinkExpired from '../Screens/LinkExpired/LinkExpired';
import CheckinUnscheduled from '../Screens/CheckinUnscheduled/CheckinUnscheduled';
import TermsAndConditions from '../Screens/Login/TermsAndConditions';



const Routes = () => {
    const [notify] = useSelector((state: RootState) => [state.snackbar]);
    const dispatch = useDispatch();
    const hNotify = () => {
        dispatch(SetNotify({ show: false }));
    };
    return (
        <>
            <Snackbars show={notify.show} hide={hNotify} type={notify.type} message={notify.message} />
            <Switch>
                <Route path="/organisations" component={CheckinUnscheduled} />
                <Route path="/visitors" component={Login} />
                <Route exact path='/' component={Home} />
                <Route exact path="/doorunlock" component={DoorUnlocked} />
                <Route exact path="/Checkin" component={Checkin} />
                <Route exact path="/visitorPass" component={visitorPass} />
                {/* <Route exact path="/residentialSetup" component={ResidentialSetup} />
                <Route exact path="/textKey" component={TextKey} />
                <Route exact path="/QRAccess" component={QRAccess} /> */}
                <Route exact path="/linkExpired" component={LinkExpired} />
            </Switch>
        </>
    )
}

export default Routes;
