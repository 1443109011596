import React from 'react';
import { MainTitle, Para, SubTitle } from './Login.styles';
import { Button, IconButton } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const TermsAndConditions = ({ onProceed }: any) => {
    const goBack = (accept: boolean) => {
        onProceed && onProceed(accept);
    }

    return (
        <div style={{ padding: '0 2rem' }}>
            <MainTitle>Terms and Conditions for the Visitor Management System</MainTitle>
            <IconButton style={{ position: 'absolute', top: '1rem', right: '0.5rem' }} onClick={() => { goBack(false) }}><CloseRoundedIcon /></IconButton>

            <Para>Welcome to Spintly’s Visitor Management System. By using our services, you agree to comply with the following terms and conditions. Please read them carefully.</Para>

            <SubTitle>1. Acceptance of Terms</SubTitle>
            <Para>By registering, signing in, or using the Visitor Management System, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree to these terms, please refrain from using the Visitor Management System.</Para>

            <SubTitle>2. Purpose of the System</SubTitle>
            <Para>The System is designed to facilitate visitor registration, check-in/check-out processes, and communication between visitors and their respective hosts. It also enhances security and operational efficiency for the premises.</Para>

            <SubTitle>3. Collection and Use of Information</SubTitle>
            <Para>3.1 Visitor Information: The System collects personal information such as name, contact details, photo (if applicable), and purpose of visit.
                <br />3.2 Host Notification: By providing your information, you consent to allow the System to send notifications to your host regarding your visit.
                <br />3.3 Visitor Notifications: You also consent to receive SMS or other forms of notifications related to your visit, including but not limited to check-in confirmations, security alerts, or follow-up messages.
                <br />3.4 Data Storage: All collected information is securely stored and used solely for operational purposes. For more details, refer to our Privacy Policy.
            </Para>

            <SubTitle>4. Consent to SMS Notifications</SubTitle>
            <Para>
                By using the System, you explicitly agree to:
                <ul>
                    <li>Receive SMS notifications or alerts related to your visit.</li>
                    <li> Allow the System to send SMS notifications to your host on your behalf, including your name, time of arrival, and purpose of visit.</li>
                </ul>
                You may opt out of SMS notifications by contacting us at support@spintly.com , though this may limit the functionality of the Visitor Management System.
            </Para>

            <SubTitle>5. Visitor Responsibilities</SubTitle>
            <Para>
                5.1 Accuracy of Information: Visitors are responsible for providing accurate and complete information during registration.
                <br />5.2 Compliance with Policies: Visitors must comply with all security and operational policies of the premises.
                <br />5.3 Restricted Activities: Visitors may not use the System for any unlawful activities, unauthorized access, or harm to other users.
            </Para>

            <SubTitle>6. Host Responsibilities</SubTitle>
            <Para>
                Hosts are responsible for:
                <ul>
                    <li>Responding promptly to visitor notifications.</li>
                    <li>Ensuring visitors comply with the premises’ policies.</li>
                </ul>
            </Para>

            <SubTitle>8. Limitation of Liability</SubTitle>
            <Para>The System is provided “as is” without warranties of any kind. [Visitor Management System Name] is not liable for any loss, damage, or inconvenience resulting from the use or inability to use the System.</Para>

            <SubTitle>9. Changes to Terms</SubTitle>
            <Para>We reserve the right to update these Terms and Conditions at any time. Continued use of the System after changes have been posted constitutes acceptance of the revised terms.</Para>

            <SubTitle>10. Contact Us</SubTitle>
            <Para>If you have any questions or concerns regarding these Terms and Conditions, please contact us at support@spintly.com
                By using the System, you agree to these Terms and Conditions, including consent to SMS notifications for both visitors and hosts.
            </Para>

            <SubTitle style={{ fontSize: '1rem' }}>
                By accepting these Terms and Conditions, you confirm your understanding and agreement to all the above provisions.
            </SubTitle>

            <div style={{ display: 'flex', gap: '1rem' }}>
                <Button onClick={() => { goBack(false) }} style={{ marginTop: "2rem", width: '100%', height: '3rem', backgroundColor: '#8C95AA' }}>
                    <SubTitle style={{ color: '#FFFFFF', marginBlock: '0' }}>Decline</SubTitle>
                </Button>
                <Button onClick={() => { goBack(true) }} style={{ marginTop: "2rem", width: '100%', height: '3rem', backgroundColor: '#2484DB' }}>
                    <SubTitle style={{ color: '#FFFFFF', marginBlock: '0' }}>Accept</SubTitle>
                </Button>
            </div>
        </div>
    );
};

export default TermsAndConditions;
