import styled from 'styled-components';

export const Container = styled.div`
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    @media (max-height: 380px) {
        min-height: 50rem;
        overflow-y: auto;
    }
`


export const ButtonContainer = styled.div`
    position: absolute;
    z-index: 100;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
`

export const MainTitle = styled.h1`
    font-size: 1.5rem;
    color: #1B1B1B;
    font-weight: 700;
    font-family: 'Urbanist', sans-serif !important;
`

export const Para = styled.p`
    color: #1B1B1B;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Urbanist', sans-serif !important;
`

export const SubTitle = styled.h3`
    color: #1B1B1B;
    font-size: 1.13rem;
    font-family: 'Urbanist', sans-serif !important;

`